import React from "react"
import styled from "styled-components"
import { H1, H2, H3, PS, PXS, PXXS, PM } from "@src/style/mdxStyle"

export const intlStyler = {
  h1: (...msg) => <H1 key={msg}>{msg}</H1>,
  h2: (...msg) => <H2 key={msg}>{msg}</H2>,
  h3: (...msg) => <H3 key={msg}>{msg}</H3>,
  pm: (...msg) => <PM key={msg}>{msg}</PM>,
  ps: (...msg) => <PS key={msg}>{msg}</PS>,
  pxs: (...msg) => <PXS key={msg}>{msg}</PXS>,
  pxxs: (...msg) => <PXXS key={msg}>{msg}</PXXS>,
  li: (...msg) => <li key={msg}>{msg}</li>,
  strong: (...msg) => <strong key={msg}>{msg}</strong>,
  b: (...msg) => <strong key={msg}>{msg}</strong>,
  sup: (...msg) => {
    return <sup key={msg}>{msg}</sup>
  },
  hiddensup: (...msg) => {
    return (
      <sup className="hidden" key={msg}>
        {msg}
      </sup>
    )
  },
  break: <br />,
}

export const printMessage = (intl, message, index, extras) => {
  return intl.formatMessage(
    {
      id: "none",
      defaultMessage: message,
    },
    {
      ...intlStyler,
      ...extras,
    }
  )
}

import React, { useState, useEffect, useCallback, Fragment } from "react"
import styled, { keyframes } from "styled-components"
import { CSSTransition } from "react-transition-group"
import { device } from "@src/style"
import { ImageLoader } from "./imageLoader"

const _Card = styled.div`
  position: relative;
  cursor: pointer;
  width: ${({ item }) => `${item.width}px`};
  height: ${({ loaded, item }) => (!loaded ? `${item.height}px` : `inherit`)};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin: ${({ item }) => `0px 0px ${item.spacing}px ${item.spacing}px`};
  &:first-child {
    margin-left: 0px;
  }
  border: ${({ loaded, theme }) =>
    loaded ? "none" : `1px solid ${theme.colors.primary}`};
`

const _ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};

  &:hover {
    transform: scale(1.05);
  }
`

const _Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const FadingImage = ({ aKey, item, onClick, onError }) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    setLoaded(false)
  }, [item, item.src])
  return (
    <>
      {error && (
        <div
          style={{
            display: "flex",
            "justifyContent": "center",
            "alignItems": "center",
            width: item.width,
            height: item.height,
          }}
        >
          Failed to load image
        </div>
      )}
      {!error && (
        <_Card onClick={onClick} loaded={loaded} key={aKey} item={item}>
          <ImageLoader loaded={loaded} />
          <_ImageWrapper loaded={loaded}>
            <_Image
              alt="Inspiration card image"
              src={item.url}
              ref={(_input) => {
                if (!_input) return
                const _img = _input
                const onLoad = () => {
                  setLoaded(true)
                }
                _img.onload = onLoad
                if (_img.complete) {
                  onLoad()
                }
              }}
              onError={(e) => {
                console.error(e)
                if (onError) onError(e)
                setError(true)
              }}
            />
          </_ImageWrapper>
        </_Card>
      )}
    </>
  )
}

import { device } from '@src/style'
import React, { useCallback, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import Lightbox from './lightbox'

const AnimatedLightbox = styled(Lightbox)`
  &.lightbox-enter {
    opacity: 0;
  }
  &.lightbox-enter-active {
    opacity: 1;
    transition: all ${({ timeout }) => timeout}ms;
  }
  &.lightbox-exit {
    opacity: 1;
  }
  &.lightbox-exit-active {
    opacity: 0;
    transition: all ${({ timeout }) => timeout}ms;
  }
`

const LightboxImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const LightboxImage = styled.img`
  cursor: pointer;
  max-width: 95vw;
  max-height: 80vh;
  width: auto;
  height: auto;
  z-index: 1;

  @media ${device.tablet} {
    max-width: 80vw;
    max-height: 80vh;
    width: auto;
    height: auto;
  }
`

const ImageAndDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ({
  entry,
  imageAndDetails = null,
  onNext,
  onPrevious,
  onClose,
  children,
}) => {
  const timeout = 300
  const [isShown, setShown] = useState(false)
  const handleKey = useCallback((e) => {
    switch (e.keyCode) {
      case 27: return onClose()
      case 37: return onPrevious()
      case 39: return onNext()
    }
  }, [onClose, onNext, onPrevious])
  useEffect(() => {
    setShown(entry != null)
    window.addEventListener('keydown', handleKey)
    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [entry, onClose])
  return (
    <CSSTransition
      in={isShown}
      classNames='lightbox'
      timeout={timeout}
      onExited={() => { }}
      unmountOnExit
    >
      <AnimatedLightbox
        timeout={timeout}
        onPrevious={onPrevious}
        onNext={onNext}
        onClose={() => {
          setShown(false)
          setTimeout(() => {
            onClose()
          }, timeout)
        }}
      >
        <LightboxImageWrapper>
          {imageAndDetails && imageAndDetails}
          {!imageAndDetails && (
            <ImageAndDetails>
              <LightboxImage
                alt='Lightbox image'
                onClick={e => {
                  e.stopPropagation()
                  onNext()
                }}
                src={entry.url}
              />
              {children}
            </ImageAndDetails>
          )}
        </LightboxImageWrapper>
      </AnimatedLightbox>
    </CSSTransition>
  )
}

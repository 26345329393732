import React from "react"
import { injectIntl } from "react-intl"
import { graphql } from "gatsby"
import Inspiration from "@components/inspiration/inspiration.js"
import Helmet from "react-helmet"
import SEO from "@components/seo"
import Layout from "@components/layout/rootLayout"

const LocalizedSEO = injectIntl(({ preview, json, intl }) => {
  const { messages, locale } = intl
  return (
    <>
      <SEO
        title={`${messages[json.title]} - Photography inspiration gallery`}
        banner={preview.src}
        pageLanguage={locale}
        desc={`Hand-picked inspirational ${
          messages[json.title]
        } gallery for photography enthusiasts`}
      />
      {locale == "ru" && (
        <SEO
          title={`${messages[json.title]} - Галерея вдохновляющих фотографий`}
          banner={preview.src}
          pageLanguage={locale}
          desc={`Собранная вручную вдохновляющая галерея "${
            messages[json.title]
          }" для фото-энтузиастов`}
        />
      )}
    </>
  )
})

const InspirationPage = (props) => {
  const {
    data,
    pageContext: { locale, json, insp },
  } = props

  const preview = data.allFile.edges.filter(
    (i) => i.node.relativeDirectory == insp
  )[0].node.childImageSharp.gatsbyImageData

  return (
    <Layout locale={locale}>
      <LocalizedSEO preview={preview} json={json} />
      <Inspiration {...props} />
    </Layout>
  )
}
export default InspirationPage
export const query = graphql`
  query InspirationQuery {
    allDirectory(filter: { absolutePath: { regex: "/^.*(_inspirator.+)$/" } }) {
      edges {
        node {
          relativePath
          relativeDirectory
        }
      }
    }
    allFile(
      filter: { absolutePath: { regex: "/^.*(_inspirator.+)cover.jpg$/" } }
    ) {
      edges {
        node {
          relativeDirectory
          childImageSharp {
            gatsbyImageData(width: 786, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`

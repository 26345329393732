import React from 'react'

import { FormattedMessage } from 'react-intl'
import { LocalizedLink } from '@src/components/link'

import { Level3List, Level3Item } from './styles'
import { allDirectoryEdgesToMenu } from '@src/components/common/menuHelper'
import styled from 'styled-components'

const Selected = styled.h3`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;

`

export default ({ pageContext: { locale, rootFolder }, baseSlug, data }) => {
  const menu = allDirectoryEdgesToMenu(data.allDirectory.edges, baseSlug)
  return (
    <Level3List>
      {menu.map((item, index) => {
        return (
          <>
            {item.selected ? (
              <Selected>
                <Level3Item selected={item.selected} key={index}>
                  {item.link && (
                    <LocalizedLink to={item.link}>{item.name}</LocalizedLink>
                  )}
                  {!item.link && <a>{item.name}</a>}
                </Level3Item>
              </Selected>
            ) : (
              <Level3Item selected={false} key={index}>
                {item.link && (
                  <LocalizedLink to={item.link}>{item.name}</LocalizedLink>
                )}
                {!item.link && <a>{item.name}</a>}
              </Level3Item>
            )}
          </>
        )
      })}
    </Level3List>
  )
}

import React, { useState, useEffect, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { device } from '@src/style'
import { ImageLoader } from './imageLoader'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 500;
`

const LightboxImage = styled.img`
  cursor: pointer;
  max-width: 95vw;
  max-height: 80vh;
  width: auto;
  height: auto;
  z-index: 502;

  transition: all 0.3s ease-in-out;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};

  @media ${device.tablet} {
    max-width: 80vw;
    max-height: 80vh;
    width: auto;
    height: auto;
  }
`

const ImageAndDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LightboxLoader = styled(ImageLoader)`
  z-index: 501;
`

export const FadingLightboxImage = ({ children, src, onClick }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(false)
  }, [src])

  return (
    <Wrapper>
      <LightboxLoader loaded={loaded} />}
      <ImageAndDetails>
        <LightboxImage
          alt='Inspiration lightbox image'
          ref={_input => {
            if (!_input) return
            const _img = _input
            const onLoad = () => {
              setLoaded(true)
            }
            _img.onload = onLoad
            if (_img.complete) {
              onLoad()
            }
          }}
          onClick={e => {
            e.stopPropagation()
            onClick()
          }}
          onError={e => {
            console.error(e)
          }}
          src={src}
          loaded={loaded}
        />
        {children}
      </ImageAndDetails>
    </Wrapper>
  )
}

import React, { useState } from 'react'

import Nav from '@components/nav/nav'
import Level3Nav from '@components/nav/level3Nav'
import {
  InspirationRoot,
  InspirationFullscreenWrapper,
  InspirationContentWrapper,
  InspirationDescriptionWrapper,
  InspirationDescription,
  InspirationDescriptionContent
} from './styles'
import InspirationMosaic from './inspirationMosaic'
import { FormattedMessage } from 'react-intl'
import { StyledMessage, StyledMessages } from '@components/common/styledMessage'

const Inpsiration = props => {
  const {
    data,
    pageContext: { json }
  } = props

  const [popupVisible, setPopupVisible] = useState(true)

  return (
    <InspirationRoot>
      <Nav defaultIndex={2} rootUrl='inspiration' />
      <InspirationFullscreenWrapper>
        {data && data.allDirectory && (
          <Level3Nav baseSlug='inspiration' {...props} />
        )}
      </InspirationFullscreenWrapper>
      {popupVisible && (
        <InspirationDescriptionWrapper onClick={() => setPopupVisible(false)}>
          <InspirationDescription>
            <InspirationDescriptionContent>
              <StyledMessages id={json.description} />
            </InspirationDescriptionContent>
          </InspirationDescription>
        </InspirationDescriptionWrapper>
      )}
      <InspirationContentWrapper>
        <InspirationMosaic {...props} />
      </InspirationContentWrapper>
    </InspirationRoot>
  )
}

export default Inpsiration

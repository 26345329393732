import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { intlStyler, printMessage } from "@src/style/intlStyler"

export const StyledMessage = ({ id, values }) => {
  return (
    <FormattedMessage
      id={id}
      values={{
        ...intlStyler,
        ...values,
      }}
    />
  )
}

export const StyledMessages = injectIntl(({ intl, id, values }) => {
  if (!intl.messages[id]) return ""
  return intl.messages[id].map((message, index) => {
    return (
      <span key={`${id}${index}`}>
        {printMessage(intl, message, index, values)}
      </span>
    )
  })
})

import React, { useState, useEffect, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import PhotoLightBox from '@components/shared/react-masonry-lightbox/photoLightBox'
import Mosaic from '@components/shared/react-masonry-lightbox/cachingMosaic'

import { shuffleArray } from "@src/common/utils"

import { FadingImage } from './inspirationMosaicCard'
import { FadingLightboxImage } from './lightboxCard'

const Wrapper = styled.div``

const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  opacity: 0.8;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ImageAndDetails = styled.div`
  display: flex;
  flex-direction: column;
`
const _Details = styled.div`
  color: #000;
  margin: 1rem;
  z-index: 502;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export default props => {
  const {
    pageContext: { locale, json }
  } = props

  const [activePhotoIndex, setActivePhotoIndex] = useState(null)
  const [loading, setLoading] = useState(true)
  const [randomizedEntries, setRandomizedEntries] = useState(null)
  useEffect(() => {
    var entries = json.entries
      .map(entry => {
        return entry.photos.map(photo => {
          return {
            ...photo,
            data: entry
          }
        })
      })
      .flat()
    shuffleArray(entries)
    setRandomizedEntries(entries)
  }, []) //componentDidMount

  const onNext = () => {
    setActivePhotoIndex((activePhotoIndex + 1) % randomizedEntries.length)
  }

  const onPrevious = () => {
    var newIndex = activePhotoIndex - 1
    if (newIndex < 0) {
      newIndex = randomizedEntries.length - 1
    }
    setActivePhotoIndex(newIndex % randomizedEntries.length)
  }

  const activePhoto = randomizedEntries
    ? randomizedEntries[activePhotoIndex]
    : null
  return (
    <Wrapper>
      {randomizedEntries && (
        <Mosaic
          spacing={{
            inner: 5,
            outer: 0
          }}
          imagesData={randomizedEntries}
          wantedRowHeight={450}
          onCreateRow={(row, rowIndex) => {
            return (
              <Row>
                {row.map((item, index) => {
                  const key = (rowIndex + 1) * (index + 1) * 123
                  const photoIndex = randomizedEntries.indexOf(
                    randomizedEntries.filter(
                      imageData => item.url == imageData.url
                    )[0]
                  )
                  return (
                    <FadingImage
                      key={key}
                      item={item}
                      aKey={`FadingImage${key}`}
                      onClick={() => {
                        setActivePhotoIndex(photoIndex)
                      }}
                    />
                  )
                })}
              </Row>
            )
          }}
        />
      )}
      {activePhotoIndex != null && (
        <PhotoLightBox
          imageAndDetails={
            <FadingLightboxImage onClick={onNext} src={activePhoto.url}>
              <_Details>
                <a href={activePhoto.data.website} target='_blank'>
                  Created by {activePhoto.data.name}. Click to visit gallery.
                </a>
              </_Details>
            </FadingLightboxImage>
          }
          entry={activePhoto}
          onNext={onNext}
          onPrevious={onPrevious}
          onClose={() => {
            setActivePhotoIndex(null)
          }}
        />
      )}
    </Wrapper>
  )
}

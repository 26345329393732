import React from 'react'
import { FormattedMessage } from 'react-intl'
import Linker from '@src/common/linker'

export const allDirectoryEdgesToMenu = (edges, baseSlug) => {
  return edges.map(({ node }) => {
    const categories = node.relativePath.split('/').map(pathPart => {
      const splitted = pathPart.split(/\d+\./)
      return splitted[splitted.length - 1].trim()
    })
    const category = categories[categories.length - 1]
    const name = node.categoryName || `category_${category}`
    return {
      name: <FormattedMessage id={name} />,
      link: `/${baseSlug}/${categories.join('/')}`,
      selected: Linker.urlEndsWith(category)
    }
  })
}

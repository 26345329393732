import React from 'react'
import styled, { css } from 'styled-components'
import { device } from '@src/style'

export const InspirationRoot = styled.div`
  background-color: ${props => props.theme.blog.bg};
`
export const InspirationFullscreenWrapper = styled.div`
  width: auto !important;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
  ${props => props.theme.widthLimiter}
`

export const InspirationContentWrapper = styled.div`
  width: auto !important;
  margin-left: auto;
  margin-right: auto;
  min-width: 100%;
  max-width: 100%;
  padding-bottom: 1rem;
  min-height: 500px;
  @media ${device.tablet} {
    min-height: 700px;
  }
  @media ${device.laptop} {
    min-height: 900px;
  }
  @media ${device.laptopL} {
    min-height: 1064px;
  }
`

export const InspirationRootMessageWrapper = styled.div`
  width: auto !important;
  margin-left: auto;
  margin-right: auto;
  min-width: 100%;
  max-width: 100%;
  padding-bottom: 1rem;
  min-height: 500px;
  @media ${device.laptop} {
    min-height: 600px;
  }
  @media ${device.laptopL} {
    min-height: 674px;
  }
  ${props => props.theme.widthLimiter}
`

export const InspirationRootMessage = styled.div``

export const InspirationDescriptionWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: flex;
  background: #fffa;
  justify-content: center;
  align-items: center;
`
export const InspirationDescription = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  @media ${device.tablet} {
    max-width: 80vw;
  }
  @media ${device.laptop} {
    max-width: 50vw;
  }
  
  align-items: center;
  background: #fff;
`
export const InspirationDescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
`

import React, { useState, Fragment } from 'react'
import styled, { css } from 'styled-components'
import device from './device'

const LightboxWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  user-select: none;
  z-index: 500;
  cursor: zoom-out;
`
const LightboxBackground = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => {
    if (!props.background) return '#000'
    return props.background
  }};
  opacity: 0.98;
`
const LightboxClose = styled.div`
  position: absolute;

  right: 32px;
  @media ${device.tablet} {
    right: 8.2vw;
  }
  @media ${device.laptop} {
    right: 9vw;
  }
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.4;
  z-index: 502;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const LightboxPreviousNext = styled.div`
  position: absolute;
  ${({ left }) => {
    if (left) {
      return css`
        left: 0;
      `
    } else {
      return css`
        right: 0;
      `
    }
  }};
  top: 0;
  width: 8vw;
  height: 100%;
  z-index: 502;
  cursor: pointer;

  &:hover {
    background: #fff1;
  }
`

const ArrowTop = styled.div`
  background-color: #666;
  height: 4px;
  left: -5px;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: ${({ left }) => (left ? `rotate(-45deg)` : `rotate(45deg)`)};
  transform-origin: ${({ left }) => (left ? `bottom left` : `bottom right`)};

  &:after {
    background-color: #fff;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transition: all ${({ transitionTime }) => `${transitionTime}s`};
    left: ${({ left }) => (left ? `100%` : `0`)};
    right: ${({ left }) => (left ? `0` : `100%`)};
    transition-delay: ${({ transitionDelay }) => `${transitionDelay}s`};
  }
`
const ArrowBottom = styled.div`
  background-color: #666;
  height: 4px;
  left: -5px;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: ${({ left }) => (left ? `rotate(45deg)` : `rotate(-45deg)`)};
  transform-origin: ${({ left }) => (left ? `top left` : `top right`)};

  &:after {
    background-color: #fff;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    transition: all ${({ transitionTime }) => `${transitionTime}s`};
    left: ${({ left }) => (left ? `100%` : `0`)};
    right: ${({ left }) => (left ? `0` : `100%`)};
    transition-delay: ${({ transitionDelay }) => `${transitionDelay}s`};
  }
`

const InnerArrow = styled.div`
  z-index: 505;
  cursor: pointer;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.1s;

  width: 40px;
  height: 60px;
  visibility: hidden;

  @media ${device.tablet} {
    visibility: visible;
  }

  &:active {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
  }

  &:hover,
  ${LightboxPreviousNext}:hover & {
    ${ArrowTop}:after {
      left: 0;
      right: 0;
    }
    ${ArrowBottom}:after {
      left: 0;
      right: 0;
    }
  }
`

const Arrow = ({ left }) => {
  const TRANSITION_DELAY = 0.08
  const TRANSITION_TIME = 0.15

  return (
    <InnerArrow
      left={left}
      transitionDelay={TRANSITION_DELAY}
      transitionTime={TRANSITION_TIME}
    >
      <ArrowTop
        left={left}
        transitionDelay={TRANSITION_DELAY}
        transitionTime={TRANSITION_TIME}
      />
      <ArrowBottom
        left={left}
        transitionDelay={TRANSITION_DELAY}
        transitionTime={TRANSITION_TIME}
      />
    </InnerArrow>
  )
}

export default ({
  background,
  onClose,
  onPrevious,
  onNext,
  children,
  className,
}) => {
  return (
    <LightboxWrapper
      className={className}
      onClick={() => {
        onClose()
      }}
    >
      <LightboxBackground background={background} />
      <LightboxClose
        onClick={() => {
          onClose()
        }}
      />
      <LightboxPreviousNext
        left
        onClick={e => {
          e.stopPropagation()
          onPrevious()
        }}
      >
        <Arrow left />
      </LightboxPreviousNext>
      <LightboxPreviousNext
        onClick={e => {
          e.stopPropagation()
          onNext()
        }}
      >
        <Arrow />
      </LightboxPreviousNext>
      {children}
    </LightboxWrapper>
  )
}
